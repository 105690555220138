import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { Form, Input } from 'antd';
import { IoAddCircleOutline } from "react-icons/io5";

import useCommonAPI from 'Shared/API/Common';

import useFetchData from 'Shared/Hooks/useFetchData';
import PopupModal from 'Shared/Components/PopupModal'
import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { DatetimePicker, NumberInput, SelectField, TextArea, TextInput, customizeRequiredMark } from 'Shared/Components/FormComponents'
import { showError, showSuccess } from 'Shared/Components/NotifyToast';
import { apiUrl, jwtDecode } from 'Shared/utils';

const Workers = () => {
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()
  const { organization, project } = useParams()
  const [form] = Form.useForm();
  const profile = jwtDecode()

  const [workers, setWorkers] = useState([])
  const [loading, setLoading] = useState(true);
  const [formOpen, setFormOpen] = useState(false);

  const tableRef = useRef()

  const { getWorkers } = useCommonAPI()

  const filterRef = useRef({
    "search": ""
  })

  const loadData = async () => {
    const [workers] = await Promise.all([
      getWorkers({ search: filterRef.current.search })
    ]);

    workers && setWorkers(workers)

    setLoading(false)
  }

  useEffect(() => {
    const init = async () => {
      await loadData()

      setLoading(false)
    }

    init()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)

    const res = await send({
      method: "POST",
      url: `${apiUrl}/common/workers`,
      contentType: "json",
      body: {
        "worker_name": values["worker_name"]
      }
    })

    if (res != null) {
      toggleForm()
      showSuccess(t("general_success"))

      tableRef.current.setLoading(true)

      await loadData()

      tableRef.current.setLoading(false)
    }

    setLoading(false)
  }

  const toggleForm = () => {
    if (formOpen) {
      form.resetFields()
    }
    
    setFormOpen(!formOpen)
  }

  const onSearch = async (value) => {        
    filterRef.current.search = value

    tableRef.current.setLoading(true)

    await loadData()

    tableRef.current.setLoading(false)
  }

  return (<>
    <Container fluid className="mainContainer" style={{ backgroundColor: "white", borderRadius: "15px" }}>
      <Row style={{ height: "100%" }}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={workers} count={null} ref={tableRef} onSearch={onSearch} searchPlaceHolder={t("worker_search")}
              columns={{
                "worker_name": { "label": t("workers_workerName"), "sorting": false, "nowrap": true },
              }}
              appActions={[
                Object.create({icon: <IoAddCircleOutline size={20}/>, text: t("general_add"), onClick: function() { toggleForm() }})
              ]}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 
    
    <LoadingScreen isOpen={loading} />

    <PopupModal isOpen={formOpen} close toggle={() => toggleForm()} width="80%">
      <Form form={form} layout="vertical" requiredMark={customizeRequiredMark} onFinish={onFinish} initialValues={{ "worker_name": "" }} style={{ width: "100%", padding: "10px" }}>
        <TextInput field_name={t("workers_workerName")} field_id="worker_name" required/>
      
        <ButtonGroup style={{ width: "100%" }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </ButtonGroup>
      </Form>
    </PopupModal>
  </>)
}

export default Workers
