import { useParams } from 'react-router-dom';
import { showError } from 'Shared/Components/NotifyToast'
import { useAuth0 } from '@auth0/auth0-react';

import { webUrl } from 'Shared/utils';

const useFetchData = () => {
  const routeParam = useParams()
  const { logout } = useAuth0()

  const send = async ({ method, url, params, headers, body, contentType, returnType }) => {
    const defaultHeader = {
      'Authorization': sessionStorage.getItem('accessToken'),
      'Project': routeParam.project
    }

    if (contentType === "json") {
      defaultHeader['Content-Type'] = "application/json"
      body = JSON.stringify(body)
    }
    
    try {
      const res = await fetch(`${url}${params ? `?${new URLSearchParams(params)}` : ""}`, {
        method: method,
        headers: headers ?? defaultHeader,
        body: body
      })

      if (res.status === 200) {
        switch(returnType) {
          case "json":
            return await res.json()
          case "blob":
            return await res.blob()
          default:
            return res
        }
      }
      else {
        if (res.status === 401 || res.status === 403) {
          logout({ logoutParams: { returnTo: webUrl } })
        }

        showError(await res.text())

        return null
      }
    }
    catch (error) {
      showError(`ERROR: ${error}`)
      return null
    }
  }

  return { send };
}

export default useFetchData;
