import React, { useRef, useLayoutEffect, useImperativeHandle, forwardRef } from "react";
import { Map, View, Overlay } from "ol";
import { XYZ, Vector as VectorSource, OSM } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import {  Icon, Fill, Text, Style, Circle as CircleStyle, Stroke } from "ol/style";
import { GeoJSON } from "ol/format";
import { easeOut } from 'ol/easing';
import { getVectorContext } from 'ol/render';
import { unByKey } from 'ol/Observable';

import { webUrl } from "Shared/utils";
import useLoop from "Shared/Hooks/useLoop";

import { getIconStyle, getLabelStyle, setLayerSource, mapViewConfiguration } from '../../components/utils'
import '../../components/style.css'

const MapDisplay = forwardRef(({ focus }, ref) => {
  const mapRef = useRef()

  useLayoutEffect(() => {
    const popupElement = document.getElementById('popup')

    const workerLayer = new VectorLayer({
      source: new VectorSource({}),
      style: function (feature) { 
        const online = feature.get("online")
        const warning = feature.get("high_temp") || feature.get("abnormal_heart_rate") || feature.get("sos") || feature.get("still") || feature.get("fall")

        const zIndex = online ? (warning ? 100 : 10) : 1
        const icon = online ? (warning ? "worker_warning" : "worker_normal") : "worker_offline"

        return [
          getIconStyle(icon, 30, zIndex),
          // getLabelStyle(feature.get("device_IMEI"), "black", zIndex)
        ]
      }
    })

    workerLayer.set('name', 'workerLayer')

    const dangerZoneLayer = new VectorLayer({
      source: new VectorSource({}),
      style: function (feature) {
        return new Style({
          stroke: new Stroke({ color: 'red', width: 3 }),
          fill: new Fill({ color: 'rgba(255, 0, 0, 0.1)' })
        })
      }
    })

    dangerZoneLayer.set('name', 'dangerZoneLayer')

    const map = new Map({
      target: "map",
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
          })
        }),
        dangerZoneLayer, workerLayer,
      ],
      overlays: [
        new Overlay({
          element: popupElement,
          id: "popup",
          offset: [25, 0],
        })
      ],
      view: new View({
        center: mapViewConfiguration["center"],
        extent: mapViewConfiguration["extent"],
        zoom: 0,
        showFullExtent: true
      })
    })

    map.on('click', function (e) {
      const feature_id = map.forEachFeatureAtPixel(e.pixel, function (f) {
        return f.getId()
      } , {
        layerFilter: function (layer) {
          return layer.get('name') === 'workerLayer'
        }
      })

      if (feature_id == null) {
        resetPopup()
        return
      }

      focus(feature_id, false)
    })

    mapRef.current = {
      "map": map,
      "workerLayer": workerLayer,
      "dangerZoneLayer": dangerZoneLayer
    }
  }, [])

  const sosAnimation = () => {
    function flash(feature) {
      const map = mapRef.current.map
      var tileLayer = map.getLayers().getArray()[0]
  
      const duration = 3000
  
      const start = Date.now()
      const flashGeom = feature.getGeometry().clone()
      const listenerKey = tileLayer.on('postrender', animate)
    
      function animate(event) {
        const frameState = event.frameState
        const elapsed = frameState.time - start
        if (elapsed >= duration) {
          unByKey(listenerKey)
          return
        }

        const vectorContext = getVectorContext(event)
        const elapsedRatio = elapsed / duration
        const radius = easeOut(elapsedRatio) * 25 + 5
        const opacity = easeOut(1 - elapsedRatio)
    
        const style = new Style({
          image: new CircleStyle({
            radius: radius,
            stroke: new Stroke({
              color: 'rgba(255, 0, 0, ' + opacity + ')',
              width: 0.25 + opacity,
            }),
          }),
        })
    
        vectorContext.setStyle(style)
        vectorContext.drawGeometry(flashGeom)

        map.render()
      }
    }
    
    const map = mapRef.current.map
    const features = mapRef.current.workerLayer.getSource().getFeatures()

    features.forEach((f) => {
      if (f.get("online") && f.get("sos")) {
        flash(f)
      }

      map.render()
    })
  }

  const zoomToFeature = (id, feature, content, zoom) => {
    if (mapRef.current.selected != id) {
      const map = mapRef.current.map

      const popupElement = document.getElementById('popup')
      const popup = mapRef.current.map.getOverlayById("popup")

      if ((feature.get("lon") == 0 || feature.get("lon") == null) || feature.get("lat") == 0 || feature.get("lat") == null) {      
        resetPopup()
        return
      }

      mapRef.current.selected = id

      const coor = feature.getGeometry().getCoordinates()

      if (zoom) {
        const view = map.getView()

        view.animate({
          center: coor,
          duration: 2000,
          zoom: 16
        });
      }

      popup.setPosition(coor)
      popupElement.innerHTML = content
    }
  }

  const resetPopup = () => {
    const map = mapRef.current.map
    const popup = map.getOverlayById("popup")
    const popupElement = document.getElementById('popup')

    mapRef.current.selected = null
    popup.setPosition(undefined)
    popupElement.innerHTML = undefined
  }

  useLoop(sosAnimation, 2000)

  useImperativeHandle(ref, () => ({
    getFeature: (id) => {      
      return mapRef.current.workerLayer.getSource().getFeatureById(id)
    },
    zoomToFeature: (id, feature, content, zoom) => {
      zoomToFeature(id, feature, content, zoom)
    },
    updateData: (data) => {
      const map = mapRef.current.map

      if (map) {
        setLayerSource(mapRef.current.workerLayer, data)
      }
    },
    updateZone: (data) => {
      const map = mapRef.current.map

      if (map) {
        setLayerSource(mapRef.current.dangerZoneLayer, data)

      }
    },
    closePopup: () => {
      resetPopup()
    },
    setMapExtent: (coordinate) => {
      mapRef.current.map.getView().fit([coordinate["x1"], coordinate["y1"], coordinate["x2"], coordinate["y2"]], mapRef.current.map.getSize())
    }
  }))

  return (<>
    <div style={{ height: "100%", width: "100%" }} id="map"></div>
    <div style={{ display: "none" }}>
      <div id="popup" className="popup"></div>
    </div>
  </>)
})

export default MapDisplay
