import React, { useEffect, useRef, useState } from 'react'

import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';

const useSmartwatchAPI = () => {
  const { send } = useFetchData()

  const getDevices = async (params) => {
    params = params ?? {}

    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/devices`,
      params: {
        "type": params["type"],
        "search": params["search"] ?? ""
      },
      returnType: "json"
    })

    return body
  }

  const updateWorkerAssignment = async (params) => {
    params = params ?? {}

    const res = await send({
      method: "PUT",
      url: `${apiUrl}/smartwatch/assignment`,
      contentType: "json",
      body: {
        "device_id": params["device"],
        "worker_id": params["worker_id"]
      }
    })

    return res
  } 

  return { getDevices, updateWorkerAssignment };
}

export default useSmartwatchAPI;
