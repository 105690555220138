import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'ol/ol.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import { SnackbarProvider } from 'notistack';
import WarningToast from 'Shared/Components/WarningToast';
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react';
import { webUrl } from './Shared/utils';
import 'Shared/i18n'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SnackbarProvider
    maxSnack={5} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    Components={{ warningToast: WarningToast }}
  >
    <Auth0Provider
      domain='auth.cmp.build4smart.com'
      clientId='Qj2T1hgMqfOk3iuvsiqMWfBSHrw6TpT5'
      useRefreshTokens={true}
      cacheLocation='localstorage'
      scope='offline_access'
      authorizationParams={{
        redirect_uri: webUrl,
        audience: 'cmp'
      }}
    >
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </SnackbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
