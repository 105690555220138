import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography, Stack } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import moment from 'moment';
import { saveAs } from 'file-saver';

import { MdKeyboardArrowLeft } from 'react-icons/md';
import PopupModal from 'Shared/Components/PopupModal'
import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { SelectField, customizeRequiredMark } from 'Shared/Components/FormComponents'
import { showSuccess } from 'Shared/Components/NotifyToast';
import useFetchData from 'Shared/Hooks/useFetchData';
import useLoop from 'Shared/Hooks/useLoop';
import { apiUrl } from 'Shared/utils';
import TimeSeriesPlot2 from 'Shared/Components/TimeSeriesPlot2';
import { FloatingDatePicker, FloatingDropdown, FloatingTimeRangePicker, FloatingInputDisabled } from 'Shared/Components/FloatingLabel/FloatingLabel';

const GasDataHistory = () => {
  const { t, i18n } = useTranslation()
  const { organization, project, device_id } = useParams()
  const { send } = useFetchData()
  const navigate = useNavigate()

  const [data, setData] = useState()
  const [deviceName, setDeviceName] = useState()

  const [threshold, setThreshold] = useState({})
  const [loading, setLoading] = useState(true)

  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState([new Date(moment(date).format("YYYY-MM-DD 00:00:00")), new Date(moment(date).format("YYYY-MM-DD 23:59:59"))])
  const [plotRange, setPlotRange] = useState({
    "start": 0,
    "end": 24
  })

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        loadData(),
        loadDeivceName()
      ]);

      await loadData()

      setLoading(false)
    }
    
    init()
  }, [])

  const loadDeivceName = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/gas/devices`,
      params: {
        "device_id": device_id
      },
      returnType: "json"
    })

    setDeviceName(body[0]["device_name"])
  }

  const loadData = async () => {
    const body1 = await send({
      method: "GET",
      url: `${apiUrl}/gas/threshold`,
      returnType: "json"
    })

    const th = {}

    Object.entries(body1).forEach(([k, v]) => {
      v.forEach((i) => {
        if (i["alert"] === true) {
          th[`${k}-${i["range"][0]}-${i["range"][1]}`] = i["range"]
        }
      })
    });

    setThreshold(th)

    const body2 = await send({
      method: "GET",
      url: `${apiUrl}/gas/history`,
      params: {
        "device_id": device_id,
        "start_time": moment(`${moment(date).format("YYYY-MM-DD")} ${moment(time[0]).format("HH:mm:ss")}`).unix(),
        "end_time": moment(`${moment(date).format("YYYY-MM-DD")} ${moment(time[1]).format("HH:mm:ss")}`).unix()
      },
      returnType: "json"
    })

    setData([
      ...[{...th, ...{"time": moment.unix(0)}}],
      ...body2.map((i) => ({...th, ...{
        "h2s": i["h2s"],
        "co": i["co"],
        "co2": i["co2"],
        "o2": i["o2"],
        "ch4": i["ch4"],
        "pm25": i["pm25"],
        "temperature": i["temperature"],
        "combustible_gas": i["combustible_gas"],
        "time": new Date(moment(i["updated_at"]))
      }})),
      ...[{...th, ...{"time": moment.unix(2147483648)}}],
    ])
  }

  const update = async () => {
    if (date && time) {
      setLoading(true)

      await loadData()

      const start = parseInt(moment(time[0]).startOf('hour').format('HH'))

      var end = moment(time[1])
      if (end.format("mm") !== "00") { end = end.add(1, "hours").startOf('hours') }
      end = end.format('HH')

      setPlotRange({
        "start": start,
        "end": end != "00" ? parseInt(end) : 24
      })

      setLoading(false)
    }
  }

  const report = async () => {
    setLoading(true)

    const blob = await send({
      method: "GET",
      url: `${apiUrl}/gas/report`,
      params: {
        "device_id": device_id,
        "start_time": moment(`${moment(date).format("YYYY-MM-DD")} ${moment(time[0]).format("HH:mm:ss")}`).unix(),
        "end_time": moment(`${moment(date).format("YYYY-MM-DD")} ${moment(time[1]).format("HH:mm:ss")}`).unix()
      },
      returnType: "blob"
    })

    if (blob != null) {
      saveAs(blob, `export.xlsx`)
    }

    setLoading(false)
  }

  return (<>
    <div className="mainContainer"  style={{ borderRadius: "15px", backgroundColor: "white", padding: "20px" }}>
      <Container fluid style={{ height: "100%", overflow: "auto" }} className="scollbar">
        <Row style={{ display: "flex", justifyContent: "start" }}>
          <Button variant="link" style={{ textDecoration: "none", width: "fit-content" }} onClick={() => navigate("./..")}><MdKeyboardArrowLeft />{t("general_back")}</Button>
        </Row>

        <Row className="mt-1 mb-1">
          <Col xs={12} lg={4}>
            <FloatingInputDisabled label="Device Name" name="device" value={deviceName} />
          </Col>

          <Col xs={12} lg={4}>
            <FloatingDatePicker label="Date" name="date" value={date} onChange={(e) => setDate(new Date(e))}/>
          </Col>

          <Col xs={12} lg={4}>
            <FloatingTimeRangePicker label="Time Range" name="time" value={time}  onChange={(d) => setTime([new Date(d[0]), new Date(d[1])]) }/>
          </Col>

          <Col xs={12} className="mt-2">
            <ButtonGroup style={{ width: "100%" }}>
              <Button variant="success" onClick={() => update()}>{t("general_search")}</Button>
              <Button variant="info" onClick={() => report()}>{t("general_export")}</Button>
            </ButtonGroup>
          </Col>
        </Row>

        <Row style={{ marginTop: "20px" }}>
          <Col xs={12} style={{ height: "100%", padding: "10px" }}>
            <Stack spacing={3}>
              <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {t("gas_h2s")}
                </div>

                <div style={{ flexGrow: 1, margin: "5px" }} >
                  <TimeSeriesPlot2
                    data={data ?? []}
                    threshold_key={Object.keys(threshold).filter((i) => i.split("-")[0] === "h2s")}
                    items={[
                      { "axis": "left", "dataKey":"h2s", "name": t("gas_h2s"), "stroke": "#8884d8" },
                    ]}
                    day={moment(date).format("YYYY-MM-DD")}
                    hour={[plotRange["start"], plotRange["end"]]}
                    yLeft={{ "range": [0, 15], "label": "" }}
                  />
                </div>
              </div>

              <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {t("gas_co")}
                </div>
                
                <div style={{ flexGrow: 1, margin: "5px" }} >
                  <TimeSeriesPlot2
                    data={data ?? []}
                    threshold_key={Object.keys(threshold).filter((i) => i.split("-")[0] === "co")}
                    items={[
                      { "axis": "left", "dataKey":"co", "name": t("gas_co"), "stroke": "#8884d8" },
                    ]}
                    day={moment(date).format("YYYY-MM-DD")}
                    hour={[plotRange["start"], plotRange["end"]]}
                    yLeft={{ "range": [0, 70], "label": "" }}
                  />
                </div>
              </div>

              <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {t("gas_co2")}
                </div>
                
                <div style={{ flexGrow: 1, margin: "5px" }} >
                  <TimeSeriesPlot2
                    data={data ?? []}
                    threshold_key={Object.keys(threshold).filter((i) => i.split("-")[0] === "co2")}
                    items={[
                      { "axis": "left", "dataKey":"co2", "name": t("gas_co2"), "stroke": "#8884d8" },
                    ]}
                    day={moment(date).format("YYYY-MM-DD")}
                    hour={[plotRange["start"], plotRange["end"]]}
                    yLeft={{ "range": [0, 7000], "label": "" }}
                  />
                </div>
              </div>

              <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {t("gas_o2")}
                </div>
                
                <div style={{ flexGrow: 1, margin: "5px" }} >
                  <TimeSeriesPlot2
                    data={data ?? []}
                    threshold_key={Object.keys(threshold).filter((i) => i.split("-")[0] === "o2")}
                    items={[
                      { "axis": "left", "dataKey":"o2", "name": t("gas_o2"), "stroke": "#8884d8" },
                    ]}
                    day={moment(date).format("YYYY-MM-DD")}
                    hour={[plotRange["start"], plotRange["end"]]}
                    yLeft={{ "range": [0, 100], "label": "" }}
                  />
                </div>
              </div>

              <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {t("gas_ch4")}
                </div>
                
                <div style={{ flexGrow: 1, margin: "5px" }} >
                  <TimeSeriesPlot2
                    data={data ?? []}
                    threshold_key={Object.keys(threshold).filter((i) => i.split("-")[0] === "ch4")}
                    items={[
                      { "axis": "left", "dataKey":"ch4", "name": t("gas_ch4"), "stroke": "#8884d8" },
                    ]}
                    day={moment(date).format("YYYY-MM-DD")}
                    hour={[plotRange["start"], plotRange["end"]]}
                    yLeft={{ "range": [0, 70], "label": "" }}
                  />
                </div>
              </div>

              <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {t("gas_pm25")}
                </div>
                
                <div style={{ flexGrow: 1, margin: "5px" }} >
                  <TimeSeriesPlot2
                    data={data ?? []}
                    threshold_key={Object.keys(threshold).filter((i) => i.split("-")[0] === "pm25")}
                    items={[
                      { "axis": "left", "dataKey":"pm25", "name": t("gas_pm25"), "stroke": "#8884d8" },
                    ]}
                    day={moment(date).format("YYYY-MM-DD")}
                    hour={[plotRange["start"], plotRange["end"]]}
                    yLeft={{ "range": [0, 70], "label": "" }}
                  />
                </div>
              </div>

              <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {t("gas_temperature")}
                </div>
                
                <div style={{ flexGrow: 1, margin: "5px" }} >
                  <TimeSeriesPlot2
                    data={data ?? []}
                    threshold_key={Object.keys(threshold).filter((i) => i.split("-")[0] === "temperature")}
                    items={[
                      { "axis": "left", "dataKey":"temperature", "name": t("gas_temperature"), "stroke": "#8884d8" },
                    ]}
                    day={moment(date).format("YYYY-MM-DD")}
                    hour={[plotRange["start"], plotRange["end"]]}
                    yLeft={{ "range": [0, 40], "label": "" }}
                  />
                </div>
              </div>

              <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {t("gas_combustibleGas")}
                </div>
                
                <div style={{ flexGrow: 1, margin: "5px" }} >
                  <TimeSeriesPlot2
                    data={data ?? []}
                    threshold_key={Object.keys(threshold).filter((i) => i.split("-")[0] === "combustible_gas")}
                    items={[
                      { "axis": "left", "dataKey":"combustible_gas", "name": t("gas_combustibleGas"), "stroke": "#8884d8" },
                    ]}
                    day={moment(date).format("YYYY-MM-DD")}
                    hour={[plotRange["start"], plotRange["end"]]}
                    yLeft={{ "range": [0, 1.5], "label": "" }}
                  />
                </div>
              </div>
            </Stack>
          </Col>
        </Row>
      </Container>
    </div>

    <LoadingScreen isOpen={loading} text={t("general_loading")}/>
  </>)
}

export default GasDataHistory
