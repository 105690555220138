import React from 'react'

import PopupModal from 'Shared/Components/PopupModal'

const PlaybackModal = ({ playUrl, toggle }) => {

  return (
    <PopupModal isOpen={playUrl != null} height="80%" width="80%" close toggle={toggle}>
      <div style={{ width: "100%", height: "100%", padding: "5px", overflowX: "hidden", overflowY: "hidden" }}>
        {playUrl && 
          <iframe src={playUrl} style={{ width: "100%", height: "100%" }}/>
        }
    </div>
    </PopupModal>
  )
}

export default PlaybackModal
