import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const availableLanguages = ['en', 'zh'];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['zh', 'en'],
    fallbackLng: {
      "default": ["en"]
    },
    debug: false,
  });

export default i18n;

// .init({
//   fallbackLng,
//   debug: false,
//   whitelist: availableLanguages,
//   react: {
//     useSuspense: false
//   },
//   interpolation: {
//     escapeValue: false
//   },
//   lng: initialLanguage,
// });
