import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Stack, Paper, Divider, Chip } from '@mui/material';
import Slider from "react-slick";
import moment from 'moment';          

import { FaPersonFalling } from 'react-icons/fa6';
import { GrUserWorker } from "react-icons/gr";
import { LuPersonStanding } from "react-icons/lu";
import { ImEnlarge } from "react-icons/im";
import { FaTimes } from "react-icons/fa";

import GasVault from './components/GasVault';
import "./style.css"

import useFetchData from 'Shared/Hooks/useFetchData';
import DataTable from 'Shared/Components/DataTable';
import PopupModal from 'Shared/Components/PopupModal';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { IconButtonComponent, IconComponent } from 'Shared/Components/Icon';
import { apiUrl, liveUrl, webUrl } from 'Shared/utils';
import AlertIcons from 'Pages/Smartwatch/components/AlertIcons';
import { Col, Container, Row } from 'react-bootstrap';

const alertTypes = {
  "smartwatch": "module_smartwatch",
  "proximity": "module_proximity",
  "ai_detection": "module_aiDetection",
}

const alertEvents = {
  "smartwatch": {
    "alertSOS": "smartwatch_sos",
    "alertFall": "smartwatch_fall",
    "alertStill": "smartwatch_still",
    "alert_BodyTemp": "smartwatch_highTemp",
    "alert_HeartRate": "smartwatch_abnormalHeartRate",
  },
  "proximity": {
    "danger_zone": "proximity_dangerZone"
  },
  "ai_detection": {
    "ppe": "aiDetection_ppe",
    "fall": "aiDetection_fall"
  }
}

function SliderArrow(props) {
  const { className, style, onClick } = props;
  
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const ConfinedSpaceDashboard = () => {
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()

  const [data, setData] = useState()
  const [threshold, setThreshold] = useState({})
  const [space, setSpace] = useState()
  const [loading, setLoading] = useState(true)

  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [live1, setLive1] = useState()
  const [live2, setLive2] = useState()

  useEffect(() => {
    const init = async () => {
      const body1 = await send({
        method: "GET",
        url: `${apiUrl}/gas/threshold`,
        returnType: "json"
      })

      const th = {}

      for (const [k, v] of Object.entries(body1)) {
        th[k] = []

        for (const i of v) {
          th[k].push({ value: i["range"][1] - i["range"][0], color: i["alert"] === true ? '#ff0000' : '#008000' })
        }
      }

      setThreshold(th)

      const body2 = await send({
        method: "GET",
        url: `${apiUrl}/dashboard/confined_space`,
        returnType: "json"
      })

      const d = body2.map((i) => ({
        "location": i["location"],
        "location_name": i["location_name"],
        "address": i["address"],
        "ptw_valid": i["ptw_valid"],
        "lock_opened": i["lock_opened"],
        "zones": i["zones"],
        "workers": i["workers"].map((j) => ({
          "worker_id": j["worker_id"],
          "worker_name": j["worker_name"],
          "zone_id": j["zone_id"],
          "zone_name": j["zone_name"],
          "alerts": <AlertIcons data={j} />,
          "last_update": moment(j["last_update"]).format("YYYY-MM-DD HH:mm:SS")
        })),
        "worker_num": i["worker_num"],
        "worker_alert": i["worker_alert"],
        "gasValues": i["gasValues"],
        "cameras": i["cameras"].map((j) => ({
          "online": (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
              <span style={{ height: "15px", width: "15px", backgroundColor: j["online"] ? "green" : "red", borderRadius: "50%" }}></span>
            </div>
          ),
          "name": j["name"],
          "camera_id": j["camera_id"]
        })),
        "alerts": i["alerts"].map((j) => ({
          "datetime": j["datetime"],
          "event": `${t(alertTypes[j["type"]])} (${t(alertEvents[j["type"]][j["event"]])})`,
          "target": j["target"],
          "status": j["status"] === 0 ? (
            <div style={{"width": "130px"}}>
              <Chip label="Pending" color="warning" variant="outlined" />
            </div>
          ) : (j["status"] === 1) ? (
            <div style={{"width": "130px"}}>
              <Chip label="Acknowledged" color="primary" variant="outlined" />
            </div>
          ) : (j["status"] === 2) ? (
            <div style={{"width": "130px"}}>
              <Chip label="Resolved" color="success" variant="outlined" />
            </div>
          ) : (
            <div style={{"width": "130px"}}>1</div>
          ),
        }))
      }))

      setData(d)
      setSpace(d[0])

      setLoading(false)
    }

    init()
  }, [])

  const openLive = (row) => {
    if (live1 != null) {
      if (row["camera_id"] == live1["camera_id"]) {
      return
      }
    }

    if (live2 != null) {
      if (row["camera_id"] == live2["camera_id"]) {
      return
      }
    }

    if (!live1) {
      setLive1(row)
    }
    else if (!live2) {
      setLive2(row)
    }
    else {
      return
    }
  }

  return (<>
    <div className="mainContainer" style={{ backgroundColor: "#808080", borderRadius: "15px" }}>
      {(data && space) && <>
        <div style={{ padding: "10px 30px 10px 30px" }}>
          <Slider style={{ height: "100%" }}
            infinite={false} speed={500} slidesToShow={2} slidesToScroll={1}
            nextArrow={<SliderArrow />} prevArrow={<SliderArrow />}
          >
            {data.map((i) =>
              <div>
                <div style={{ border: i["location"] === space["location"] ? '2px solid white' : "2px solid #808080", padding: "5px", borderRadius: "15px", cursor: "pointer" }} onClick={() => setSpace(i)}>
                  <div style={{ borderRadius: "15px" }}>
                    <div style={{ backgroundColor: "#404040", borderRadius: "10px" }}>
                      <div style={{ paddingLeft: "10px", color:"white" }}>{i["location_name"]}</div>
                      <div style={{ paddingLeft: "10px", color:"white" }}>{i["address"]}</div>

                      <div style={{ backgroundColor: "#D3D3D3", borderRadius: "0px 0px 10px 10px", height: "100%" }}>
                        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1} sx={{ height: "100%", justifyContent: "space-around", paddingLeft: "20px", paddingRight: "20px" }}>
                          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <div>PTW</div>

                            {i["ptw_valid"] != null ? (<>
                              {(i["ptw_valid"] === true) ? (
                                <div className='text-normal'>Valid</div>
                              ) : (
                                <div className='text-alert'>Invalid</div>
                              )}
                            </>) : (
                              <div>-</div>
                            )}

                          </div>

                          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <div>Lock</div>

                            {i["lock_opened"] != null ? (<>
                              {(i["lock_opened"] === true) ? (
                                <div className='text-normal'>Opened</div>
                              ) : (
                                <div className='text-alert'>Closed</div>
                              )}
                            </>) : (
                              <div>-</div>
                            )}
                          </div>

                          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <div>Gas Monitor</div>
                              {i["gasValues"] != null ? (<>
                                {(Object.entries(space["gasValues"]).filter(([k, v]) => v["alert"] === true).length == 0) ? (
                                  <div className='text-normal'>Normal</div>
                                ) : (
                                  <div className='text-alert'>Alert</div>
                                )}
                              </>) : (
                                <div>-</div>
                              )}
                          </div>

                          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <div># Workers</div>
                            {(i["worker_alert"] === false) ? (
                              <div className='text-normal'>{i["worker_num"]}</div>
                            ) : (
                              <div className='text-alert'>{i["worker_num"]}</div>
                            )}
                          </div>
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Slider>
        </div>

        <div style={{ display: "flex", flexFlow: "row", height: "100%", width: "100%", borderRadius: "15px", padding: "10px", overflow: "hidden" }}>
          <div style={{ width: "100%", height: "100%", overflow:"auto" }} className='scollbar'>
            <Container style={{ flexGrow: 1, height: "100%", minHeight: "850px", paddingLeft: "5px" }}>
              <Row className='h-50' style={{ display: "flex", flexDirection: "row", paddingBottom: "5px" }}>
                <Col xs={6} style={{ height: "100%", display: "flex", flexDirection: "row", paddingRight: "5px" }}>
                  <div style={{ width: "100%", height: "100%", backgroundColor: "#FFFFFF", borderRadius: "10px", display: "flex", flexDirection: "column", overflow: "auto" }} className="scollbar">
                    <Stack direction="row" sx={{ padding: "5px", justifyContent: "space-between" }}>
                      <div><b>{t("module_gas")}</b></div>
                      {space["gasValues"] ? (
                        <div><b>Last Update: {space["gasValues"]["last_update"]}</b></div>
                      ) : (
                        <div></div>
                      )}
                    </Stack>

                    {space["gasValues"] ? (
                      <div style={{ width: "100%", flexGrow: 1, display: "flex", flexDirection: "column", overflow: "auto", alignItems: "center" }} className="scollbar">
                        <Stack direction="column" spacing={2} style={{ margin: "auto" }}>
                          <Stack direction="row" spacing={2}>
                            <GasVault data={threshold["o2"]} value={space["gasValues"]["o2"]["value"]} label={`${t("gas_o2")}: ${space["gasValues"]["o2"]["value"]} %`} labelColor={space["gasValues"]["o2"]["alert"] ? "#FF0000" : "#008000"}/>
                            <GasVault data={threshold["temperature"]} value={space["gasValues"]["temperature"]["value"]} label={`${t("gas_temperature")}: ${space["gasValues"]["temperature"]["value"]} °C`} labelColor={space["gasValues"]["temperature"]["alert"] ? "#FF0000" : "#008000"}/>
                            <GasVault data={threshold["pm25"]} value={space["gasValues"]["pm25"]["value"]} label={`${t("gas_pm25")}: ${space["gasValues"]["pm25"]["value"]} ppm`} labelColor={space["gasValues"]["pm25"]["alert"] ? "#FF0000" : "#008000"}/>
                            <GasVault data={threshold["co"]} value={space["gasValues"]["co"]["value"]} label={`${t("gas_co")}: ${space["gasValues"]["co"]["value"]} ppm`} labelColor={space["gasValues"]["co"]["alert"] ? "#FF0000" : "#008000"}/>
                          </Stack>
                          <Stack direction="row" spacing={2}>
                            <GasVault data={threshold["co2"]} value={space["gasValues"]["co2"]["value"]} label={`${t("gas_co2")}: ${space["gasValues"]["co2"]["value"]} ppm`} labelColor={space["gasValues"]["co2"]["alert"] ? "#FF0000" : "#008000"}/>
                            <GasVault data={threshold["h2s"]} value={space["gasValues"]["h2s"]["value"]} label={`${t("gas_h2s")}: ${space["gasValues"]["h2s"]["value"]} ppm`} labelColor={space["gasValues"]["h2s"]["alert"] ? "#FF0000" : "#008000"}/>
                            <GasVault data={threshold["ch4"]} value={space["gasValues"]["ch4"]["value"]} label={`${t("gas_ch4")}: ${space["gasValues"]["ch4"]["value"]} ppm`} labelColor={space["gasValues"]["ch4"]["alert"] ? "#FF0000" : "#008000"}/>
                            <GasVault data={threshold["combustible_gas"]} value={space["gasValues"]["combustible_gas"]["value"]} label={`${t("gas_combustibleGas")}: ${space["gasValues"]["combustible_gas"]["value"]} ppm`} labelColor={space["gasValues"]["combustible_gas"]["alert"] ? "#FF0000" : "#008000"}/>
                          </Stack>
                        </Stack>
                      </div>
                    ) : (
                      <div style={{ width: "100%", flexGrow: 1, display: "flex", flexDirection: "column", overflow: "auto", alignItems: "center", justifyContent: "center" }}>
                        <div><b>No information available</b></div>
                      </div>
                    )}
                  </div>
                </Col>

                <Col xs={6} style={{ height: "100%", display: "flex", flexDirection: "row", paddingLeft: "5px" }}>
                  <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
                    <div style={{ padding: "5px" }}>
                      <div style={{ fontSize: "20px" }}><b>Number of Workers: {space["workers"].length}</b></div>

                      <Stack direction="row" spacing={2}>
                        {space["zones"].map((i) => 
                          <div>
                            <span style={{ display: "inline-block", fontSize: "20px" }}><b>{i["zone_name"]}:&nbsp;</b></span>
                            <span style={{ display: "inline-block", color: "green", alignItems: 'center', fontSize: "20px" }}><b>{space["workers"].filter((j) => i["zone_id"] == j["zone_id"]).length}</b></span>
                          </div>
                        )}
                      </Stack>
                    </div>

                    <Paper elevation={0} sx={{ flex: 1, display: "flex", flexFlow: "column", overflow: "auto", borderRadius: "10px" }}>
                      <DataTable
                        data={space["workers"]}
                        columns={{
                          "worker_name": { "label": t("smartwatch_worker"), "sorting": false, "nowrap": true },
                          "zone_name": { "label": t("smartwatch_zone"), "sorting": false, "nowrap": true },
                          "alerts": { "label": t("smartwatch_alerts"), "sorting": false, "nowrap": true },
                          "last_update": { "label": t("smartwatch_lastUpdate"), "sorting": false, "nowrap": true }
                        }}
                      />
                    </Paper>
                  </div>
                </Col>
              </Row>

              <Row className='h-50' style={{ display: "flex", flexDirection: "row", paddingTop: "5px" }}>
                <Col xs={6} style={{ height: "100%", display: "flex", flexDirection: "row", paddingRight: "5px" }}>
                  <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
                    <div style={{ padding: "5px" }}><b>{t("module_cameras_live")}</b></div>

                    <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "100%", overflow: "auto" }} className='scolbar' >
                      {space["cameras"].length > 0 ? (<>
                        <div style={{ width: "50%", height: "100%" }}>
                          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
                            <DataTable
                              onRowClick={(_, row) => openLive(row)}
                              data={space["cameras"]}
                              columns={{
                                "online": { "label": "", "sorting": false, "nowrap": true },
                                "name": { "label": t("cameras_cameraName"), "sorting": false, "nowrap": true },
                              }}
                            />
                          </Paper>
                        </div>
                      
                        <div style={{ width: "50%", paddingLeft: "10px", paddingRight: "10px" }}>
                          <div style={{ height: "50%" }}>
                            {live1 ? (<>
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div>{live1["name"]}</div>

                                <Stack direction="row" spacing={1}>
                                  <IconButtonComponent onClick={() => setOpen1(true)}>
                                    <ImEnlarge size={10} />
                                  </IconButtonComponent>
                                  <IconButtonComponent onClick={() => setLive1()}>
                                    <FaTimes size={10} />
                                  </IconButtonComponent>
                                </Stack>
                              </div>

                              <div style={{ width: "100%", flex: 1 }}>
                                <iframe src={`${ liveUrl }/live/${live1["camera_id"]}`} style={{ width: "100%", height: "100%", pointerEvents: "none" }} />
                              </div>
                            </>) : (
                              <div></div>
                            )}
                          </div>

                          <div style={{ height: "50%" }}>
                            {live2 ? (<>
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div>{live2["name"]}</div>

                                <Stack direction="row" spacing={1}>
                                  <IconButtonComponent onClick={() => setOpen2(true)}>
                                    <ImEnlarge size={10} />
                                  </IconButtonComponent>
                                  <IconButtonComponent onClick={() => setLive2()}>
                                    <FaTimes size={10} />
                                  </IconButtonComponent>
                                </Stack>
                              </div>

                              <div style={{ width: "100%", flex: 1 }}>
                                <iframe src={`${ liveUrl }/live/${live2["camera_id"]}`} style={{ width: "100%", height: "100%", pointerEvents: "none" }} />
                              </div>
                            </>) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      </>) : (
                        <div style={{ width: "100%", flexGrow: 1, display: "flex", flexDirection: "column", overflow: "auto", alignItems: "center", justifyContent: "center" }}>
                          <div><b>No information available</b></div>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>

                <Col xs={6} style={{ height: "100%", display: "flex", flexDirection: "row", paddingLeft: "5px" }}>
                  <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
                    <div style={{ padding: "5px" }}><b>Recent Alert</b></div>

                    <Paper elevation={0} sx={{ flex: 1, display: "flex", flexFlow: "column", overflow: "auto", borderRadius: "10px" }}>
                      <DataTable
                        data={space["alerts"]}
                        columns={{
                          "datetime": { "label": "Datetime", "sorting": false, "nowrap": true },
                          "event": { "label": "Event", "sorting": false, "nowrap": true },
                          "target": { "label": "Target", "sorting": false, "nowrap": true },
                          "status": { "label": "Status", "sorting": false, "nowrap": true },
                        }}
                      />
                    </Paper>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          
        </div>
      </>}
    </div>

    <PopupModal width="80%" height="80%" isOpen={open1} close toggle={() => setOpen1(false)}>
      {live1 && 
        <div style={{ overflow: "hidden", width: "100%", height: "100%" }}>
          <div>{live1["name"]}</div>
          <iframe src={`${ liveUrl }/live/${live1["camera_id"]}`} style={{ width: "100%", height: "100%", pointerEvents: "none" }} />
        </div>
      }
    </PopupModal>

    <PopupModal width="80%" height="80%" isOpen={open2} close toggle={() => setOpen2(false)}>
      {live2 && 
        <div style={{ overflow: "hidden", width: "100%", height: "100%" }}>
          <div>{live2["name"]}</div>
          <iframe src={`${ liveUrl }/live/${live2["camera_id"]}`} style={{ width: "100%", height: "100%", pointerEvents: "none" }} />
        </div>
      }
    </PopupModal>

    <LoadingScreen isOpen={loading} text={t("general_loading")}/>
  </>)
}

export default ConfinedSpaceDashboard
