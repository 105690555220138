import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

const LoadingScreen = ({ isOpen }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={isOpen}>
      <div style={{ display: "flex", flexFlow: "row", padding: "20px" }}>
        <CircularProgress />
      </div>
    </Backdrop>
  )
}

export default LoadingScreen