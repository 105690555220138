import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material'
import { MdOutlineSos, MdCrisisAlert } from 'react-icons/md';
import { FaTemperatureHigh, FaHeartbeat } from "react-icons/fa";
import { LuPersonStanding } from "react-icons/lu";
import { FaPersonFalling } from "react-icons/fa6";
import { MdWatchOff } from "react-icons/md";

import { IconComponent } from 'Shared/Components/Icon';

import React from 'react'

const AlertIcons = ({ data }) => {
  const { t, i18n } = useTranslation()

  const alertIcons = {
    "sos": {
      "text": "smartwatch_sos",
      "icon": <MdOutlineSos />
    },
    "still" : {
      "text": "smartwatch_still",
      "icon": <LuPersonStanding />
    },
    "fall": {
      "text": "smartwatch_fall",
      "icon": <FaPersonFalling />
    },
    "danger_zone": {
      "text": "smartwatch_intrusion",
      "icon": <MdCrisisAlert />
    },
    "high_temp": {
      "text": "smartwatch_highTemp",
      "icon": <FaTemperatureHigh />
    },
    "abnormal_heart_rate": {
      "text": "smartwatch_abnormalHeartRate",
      "icon": <FaHeartbeat />
    },
    // "offline" : {
    //   "text": "smartwatch_offline",
    //   "icon": <MdWatchOff />
    // }
  }

  const activeWarning = Object.keys(alertIcons).filter((i) => data[i] == true)
    
  if (activeWarning.length === 0) {
    return <div>-</div>
  }

  return (
    <Stack justifyContent="center" direction="row" spacing={2}>
      {activeWarning.map((i) =>
        <IconComponent title={t(alertIcons[i]["text"])}>
          {alertIcons[i]["icon"]}
         </IconComponent>
      )}
    </Stack>
  )
}

export default AlertIcons
