import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom'
import { Box, Menu, Stack, Typography, Paper, Tabs, Tab } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import ConfinedSpaceDashboard from './Dashboard/ConfinedSpace/ConfinedSpaceDashboard';

import LoadingScreen from 'Shared/Components/LoadingScreen';
import useFetchData from 'Shared/Hooks/useFetchData';
import AlertSummary from './Dashboard/AlertSummary/AlertSummary';

const Dashboard = () => {
  const { user, logout } = useAuth0()
  const { organization, project } = useParams()
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()
  const { projectInfo } = useOutletContext()

  const [currentTab, setCurrentTab] = useState("alert");

  return (<>
    <div className="mainContainer">
      <Tabs value={currentTab} onChange={(_, value) => setCurrentTab(value)}>
        <Tab label="Alert Summary" value="alert"/>
        <Tab label="Confined Space" value="confined_space"/>
      </Tabs>

      {currentTab === "alert" && 
        <Box style={{ flexGrow: 1, overflow: "auto" }} className='scollbar'>
          <AlertSummary />
        </Box>
      }

      {currentTab === "confined_space" && 
        <Box style={{ flexGrow: 1, overflow: "auto" }} className='scollbar'>
          <ConfinedSpaceDashboard />
        </Box>
      }
    </div>
  </>)
}

export default Dashboard
