import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom'
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Menu, Stack, Typography, Paper } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Form, TreeSelect, DatePicker, Checkbox } from 'antd';

import { HiSignal, HiSignalSlash } from "react-icons/hi2";
import { IoIosAlert } from "react-icons/io";
import { MdOutlinePending } from "react-icons/md";
import { FiCheckCircle } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { MdOutlineTimerOff } from "react-icons/md";

import { IconButtonComponent } from 'Shared/Components/Icon';
import PopupModal from 'Shared/Components/PopupModal';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import useLoop from 'Shared/Hooks/useLoop';
import useWindowSize from 'Shared/Hooks/useWindowSize';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl, webUrl } from 'Shared/utils';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from 'recharts';
import moment from 'moment';
import dayjs from 'dayjs';

// const Grid = ({ icon, title, data, backgroundColor }) => {
//   return (
//     <div style={{ display: "flex", alignItems: "center", height: "50px", width: "130px", borderRadius: "5px", backgroundColor: backgroundColor, padding: "10px" }}>
//       {icon}
//       <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
//       <div>{title}</div>
//         <div>{data}</div>
//       </div>
//     </div>
//   )
// }

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{payload.name}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`Count: ${payload.value}`}
      </text>
    </g>
  );
};

const AlertSummary = () => {
  const { user, logout } = useAuth0()
  const { organization, project } = useParams()
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()
  const { projectInfo } = useOutletContext()

  const [loading, setLoading] = useState(true);

  // const [reportConfnedSpaceSmartwatch, setReportConfnedSpaceSmartwatch] = useState()
  // const [reportOutdoorSmartwatch, setReportOutdoorSmartwatch] = useState()
  // const [reportCameras, setReportCameras] = useState()
  // const [reportPtw, setReportPtw] = useState()
  const [reportAlert, setReporAlert] = useState()

  // const [activeIndex, setActiveIndex] = useState()
  const [projectModules, setProjectModules] = useState()
  const [alertExist, setAlertExist] = useState(false)

  const [activeIndex, setActiveIndex] = useState(null)

  // const getReportConfnedSpaceSmartwatch = async () => {
  //   const body = await send({
  //     method: "GET",
  //     url: `${apiUrl}/smartwatch/report/confined_space`,
  //     returnType: "json"
  //   })

  //   setReportConfnedSpaceSmartwatch(body)
  // }

  // const getReportOutdoorSmartwatch = async () => {
  //   const body = await send({
  //     method: "GET",
  //     url: `${apiUrl}/smartwatch/report/outdoor`,
  //     returnType: "json"
  //   })

  //   setReportOutdoorSmartwatch(body)
  // }

  // const getReportCameras = async () => {
  //   const body = await send({
  //     method: "GET",
  //     url: `${apiUrl}/camera/report`,
  //     returnType: "json"
  //   })

  //   setReportCameras(body)
  // }

  // const getReportPtw = async () => {
  //   const body = await send({
  //     method: "GET",
  //     url: `${apiUrl}/permit_to_work/report`,
  //     returnType: "json"
  //   })

  //   setReportPtw(body)
  // }

  const getReportAlert = async (modules, dateRange) => {
    dateRange = dateRange ?? [new Date(), new Date()]

    const body = await send({
      method: "GET",
      url: `${apiUrl}/alert/report`,
      params: {
        "start_date": moment(dateRange[0]).format("YYYY-MM-DD"),
        "end_date": moment(dateRange[1]).format("YYYY-MM-DD")
      },
      returnType: "json"
    })

    const getCount = (type) => {
      const item = body.filter((i) => i["type"] === type)

      if (item.length === 0) {
        return 0
      }

      if (alertExist === false) {
        setAlertExist(true)
      }

      return item[0]["count"]
    }

    const data = []

    if (modules.includes("outdoor_smartwatch") || modules.includes("confined_space_smartwatch")) {
      data.push({ "name": t("module_smartwatch"), "value": getCount("smartwatch") })
    }

    if (modules.includes("ai_detection")) {
      data.push({ "name": t("module_aiDetection"), "value": getCount("ai_detection") })
    }

    if (modules.includes("proximity")) {
      data.push({ "name": t("module_proximity"), "value": getCount("proximity") })
    }

    setReporAlert(data)
  }

  useEffect(() => {
    const init = async (modules) => {
      setLoading(true)

      setAlertExist(false)
      setProjectModules(modules)
      // setReportConfnedSpaceSmartwatch()
      // setReportOutdoorSmartwatch()
      // setReportCameras()
      // setReportPtw()

      await Promise.all([
        getReportAlert(modules),
        // modules.includes("confined_space_smartwatch") && getReportConfnedSpaceSmartwatch(),
        // modules.includes("outdoor_smartwatch") && getReportOutdoorSmartwatch(),
        // modules.includes("cameras") && getReportCameras(),
        // modules.includes("permit_to_work") && getReportPtw()
      ]);

      setLoading(false)
    }

    if (projectInfo != null) {
      const modules = projectInfo["modules"].filter((i) => i["enabled"] === 1).map((i) => i["module"])

      init(modules)
    }
  }, [projectInfo])

  const handleReportDateChange = async (value) => {
    setLoading(true)

    await getReportAlert(projectModules, [new Date(value[0]), new Date(value[1])])

    setLoading(false)
  }

  const onPieEnter = (_, index) => {
    setActiveIndex(index)
  };

  return (<>
    <div className="mainContainer">
      <div style={{ width: "100%", height: "100%", overflow: "auto", scrollbarWidth: 'none' }}>
        {(projectInfo != null && loading === false) &&
          <Stack direction="column" spacing={2}>
            {reportAlert && 
              <Paper elevation={3} style={{ padding: "10px", width: "100%", height: alertExist ? "300px" : "80px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingBottom: "10px" }}>
                  <div>{t("alert_alertSummary")}</div>

                  <div style={{ width: "50%" }}>
                    <DatePicker.RangePicker
                      style={{ width: "100%" }} maxDate={dayjs(moment().format("YYYY-MM-DD"))}
                      popupStyle={{ zIndex: 1500 }}
                      defaultValue={[dayjs(new Date()), dayjs(new Date())]}
                      onChange={(value) => handleReportDateChange(value)}
                    />
                  </div>
                </div>
                
                
                {alertExist ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart width="100%" height="100%">
                      <Pie
                        dataKey="value"
                        isAnimationActive={true}
                        data={reportAlert}
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        fill="#8884d8"
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        onMouseEnter={onPieEnter}
                      >
                        {(projectModules.includes("outdoor_smartwatch") || projectModules.includes("confined_space_smartwatch")) && <>
                          <Cell key="smartwatch_ack" fill="#FF9999" />
                          <Cell key="smartwatch_noAck" fill="#FF0000" />
                        </>}

                        {projectModules.includes("cameras") && <>
                          <Cell key="ai_detection_ack" fill="#99FF99" />
                          <Cell key="ai_detection_anoAck" fill="#00FF00" />
                        </>}
                        
                        {projectModules.includes("proximity") && <>
                          <Cell key="proximity_ack" fill="#9999FF" />
                          <Cell key="proximity_noAck" fill="#0000FF" />
                        </>}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" , justifyContent: "center"}}>
                    <div>{t("alert_noRecord")}</div>
                  </div>
                )}
              </Paper>
            }
          
            {/* {reportCameras &&
              <Paper elevation={3} style={{ padding: "10px" }}>
                <div style={{ paddingBottom: "10px" }} >{t("module_cameras")}</div>
                <Stack direction="row" spacing={2}>
                  <Grid icon={<HiSignal size={30} style={{ color: "green" }}/>} title={t("cameras_online")} data={reportCameras["online"]} backgroundColor={"#66ff99"}/>
                  <Grid icon={<HiSignalSlash size={30} style={{ color: "grey" }}/>} title={t("cameras_offline")} data={reportCameras["offline"]} backgroundColor={"#bfbfbf"}/>
                </Stack>
              </Paper>
            } */}

            {/* {reportConfnedSpaceSmartwatch &&
              <Paper elevation={3} style={{ padding: "10px" }}>
                <div style={{ paddingBottom: "10px" }} >{t("module_confinedSpaceSmartwatch")}</div>
                <Stack direction="row" spacing={2}>
                  <Grid icon={<HiSignal size={30} style={{ color: "green" }}/>} title={t("smartwatch_online")} data={reportConfnedSpaceSmartwatch["online"]} backgroundColor={"#66ff99"}/>
                  <Grid icon={<HiSignalSlash size={30} style={{ color: "grey" }}/>} title={t("smartwatch_offline")} data={reportConfnedSpaceSmartwatch["offline"]} backgroundColor={"#bfbfbf"}/>
                </Stack>
              </Paper>
            } */}

            {/* {reportOutdoorSmartwatch &&
              <Paper elevation={3} style={{ padding: "10px" }}>
                <div style={{ paddingBottom: "10px" }} >{t("module_outdoorSmartwatch")}</div>
                <Stack direction="row" spacing={2}>
                  <Grid icon={<HiSignal size={30} style={{ color: "green" }}/>} title={t("smartwatch_online")} data={reportOutdoorSmartwatch["online"]} backgroundColor={"#66ff99"}/>
                  <Grid icon={<HiSignalSlash size={30} style={{ color: "grey" }}/>} title={t("smartwatch_offline")} data={reportOutdoorSmartwatch["offline"]} backgroundColor={"#bfbfbf"}/>
                </Stack>
              </Paper>
            } */}

            {/* {reportPtw &&
              <Paper elevation={3} style={{ padding: "10px" }}>
                <div style={{ paddingBottom: "10px" }} >{t("module_permitToWork")}</div>
                <Stack direction="row" spacing={2}>
                  <Grid icon={<MdOutlinePending size={30} style={{ color: "grey" }}/>} title={t("ptw_pending")} data={reportPtw["pending"]} backgroundColor={"#bfbfbf"}/>
                  <Grid icon={<FiCheckCircle size={30} style={{ color: "green" }}/>} title={t("ptw_active")} data={reportPtw["approved"]} backgroundColor={"#66ff99"}/>
                  <Grid icon={<MdOutlineTimerOff size={30} style={{ color: "red" }}/>} title={t("ptw_expired")} data={reportPtw["expired"]} backgroundColor={"pink"}/>
                </Stack>
              </Paper>
            } */}
          </Stack>
        }
      </div>
    </div>

    <LoadingScreen isOpen={loading} />
  </>)
}

export default AlertSummary


