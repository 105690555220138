import react, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";

import Main from "Pages/Main/Main";
import Dashboard from "Pages/Main/components/Dashboard";
import Alert from "Pages/Alert/Alert";
import Live from "Pages/Live/Live";
import Workers from "Pages/Workers/Workers";

import PermitToWorkList from "Pages/PermitToWork/PermitToWorkList";
import PermitToWorkDetail from "Pages/PermitToWork/PermitToWorkDetail";
import PermitToWorkTemplates from "Pages/PermitToWork/PermitToWorkTemplates";

import AssetList from "Pages/AssetsTracking/AssetList";
import AssetTypes from "Pages/AssetsTracking/AssetTypes";
import AssetCertificates from "Pages/AssetsTracking/AssetCertificates";

// import AIDetection from "Pages/AIDetection/AIDetection";
// import AIDetectionDetail from "Pages/AIDetection/AIDetectionDetail";

import GasData from "Pages/Gas/GasData";

import VRTrainingRecord from "Pages/VRTraining/VRTrainingRecord";

import CameraList from "Pages/Cameras/CameraList";
import CameraPlayback from "Pages/Cameras/CameraPlayback";

import SmartwatchConfinedSpace from "Pages/Smartwatch/ConfinedSpace/SmartwatchConfinedSpace";
import SmartwatchConfinedSpaceZones from "Pages/Smartwatch/ConfinedSpace/SmartwatchConfinedSpaceZones";
import SmartwatchConfinedSpaceHistory from "Pages/Smartwatch/ConfinedSpace/SmartwatchConfinedSpaceHistory";
import SmartwatchConfinedSpaceDevices from "Pages/Smartwatch/ConfinedSpace/SmartwatchConfinedSpaceDevices";
import SmartwatchOutdoor from "Pages/Smartwatch/Outdoor/SmartwatchOutdoor";
import SmartwatchOutdoorDevices from "Pages/Smartwatch/Outdoor/SmartwatchOutdoorDevices";

import ProximityList from "Pages/Proximity/ProximityList";

import SmartlockList from "Pages/Smartlock/SmartlockList";

import { NotifyToast } from 'Shared/Components/NotifyToast'
import { jwtDecode, webUrl } from "Shared/utils";
import SmartwatchOutdoorHistory from "Pages/Smartwatch/Outdoor/SmartwatchOutdoorHistory";
import GasDataHistory from "Pages/Gas/GasDataHistory";

const Portal = () => {
  const { error, isAuthenticated, isLoading, user, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const { t, i18n } = useTranslation()

  const [start, setStart] = useState(false)

  useEffect(() => {
    let token = null

    const getToken = async () => {
      token = await getAccessTokenSilently()
      sessionStorage.setItem('accessToken', token)

      const payload = jwtDecode(token)
      const lifetime = payload["exp"] - moment().unix()
    
      setTimeout(async function()  {
        await getToken()
      }, (lifetime - 60)*1000);
    }

    const authenticate = async () => {
      try {
        await getToken()
        setStart(true)
      }
      catch (e){
        logout({ logoutParams: { returnTo: webUrl } })
      }
    }

    if (!isLoading) {
      if (!error) {
        if (isAuthenticated) {
          authenticate()
        }
        else {
          loginWithRedirect({
            appState: {
              returnTo: webUrl
            }
          })
        }
      }
      else {
        const errorMsg = new String(error).toString()

        switch (errorMsg) {
          case "Error: Please verify your email before logging in.":
            alert(t("loginError_unverified"))
            break
          case "Error: client requires organization membership, but user does not belong to any organization":
            alert(t("loginError_noOrg"))
            break
          default:
            alert(errorMsg)
            break
        }

        logout({ logoutParams: { returnTo: webUrl } })
      }
    }
  }, [isLoading, isAuthenticated])

  return (<>
    {start &&
      <Routes>
        <Route path='/portal/:organization?/:project?' element={<Main />}>
          <Route index element={<Dashboard />} />

          <Route exact path="alert" element={<Alert />} />
          <Route exact path="live" element={<Live />} />
          <Route exact path="workers" element={<Workers />} />

          <Route exact path="cameras/list" element={<CameraList />} />
          {/* <Route exact path="cameras/live" element={<CameraLive />} /> */}
          <Route exact path="cameras/playback" element={<CameraPlayback />} />

          <Route exact path="proximity/list" element={<ProximityList />} />
          {/* <Route exact path="proximity/live" element={<ProximityLive />} /> */}

          {/* <Route exact path="ai_detection" element={<AIDetection />} />
          <Route path="ai_detection/:camera_id" element={<AIDetectionDetail />} /> */}

          <Route exact path="permit_to_work/list" element={<PermitToWorkList />} />
          <Route path="permit_to_work/list/:template_id/:form_id?" element={<PermitToWorkDetail />} />
          <Route exact path="permit_to_work/templates" element={<PermitToWorkTemplates />} />

          <Route exact path="assets_tracking/list" element={<AssetList />} />
          <Route exact path="assets_tracking/types" element={<AssetTypes />} />
          <Route exact path="assets_tracking/certificates" element={<AssetCertificates />} />

          <Route exact path="gas/list" element={<GasData />} />
          <Route exact path="gas/list/:device_id" element={<GasDataHistory />} />

          <Route exact path="vr_training/records" element={<VRTrainingRecord />} />
        
          <Route exact path="confined_space_smartwatch/list" element={<SmartwatchConfinedSpace />} />
          <Route exact path="confined_space_smartwatch/devices" element={<SmartwatchConfinedSpaceDevices />} />
          <Route exact path="confined_space_smartwatch/zones" element={<SmartwatchConfinedSpaceZones />} />
          <Route exact path="confined_space_smartwatch/history" element={<SmartwatchConfinedSpaceHistory />} />

          <Route exact path="outdoor_smartwatch/list" element={<SmartwatchOutdoor />} />
          <Route exact path="outdoor_smartwatch/devices" element={<SmartwatchOutdoorDevices />} />
          <Route exact path="outdoor_smartwatch/history" element={<SmartwatchOutdoorHistory />} />

          <Route exact path="smartlock/list" element={<SmartlockList />} />
        </Route>

        <Route index element={<Navigate to="./portal" replace />} />
      </Routes>
    }

    <div>{<NotifyToast/>}</div>
  </>)
}

function App() {
  const location = useLocation()

  return (<Portal />)
}

export default App;
