export const webUrl = `${window.location.origin}${process.env.PUBLIC_URL}`
export const apiUrl = process.env.REACT_APP_API ?? `${window.location.origin}/api`
export const liveUrl = process.env.REACT_APP_LIVE ?? `${window.location.protocol}//streaming.${window.location.hostname}`
export const playbackUrl = process.env.REACT_APP_PLAYBACK ?? `${window.location.protocol}//streaming.${window.location.hostname}/playback`

export function jwtDecode(token) {
  token = token ?? sessionStorage.getItem('accessToken')

  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''))

  return JSON.parse(jsonPayload)
}